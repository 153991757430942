section#not-found{
    padding: 124px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
}

section#not-found h3,
section#not-found p{
    margin: 0;
    padding: 0;
    font-size: 3rem;
}

@media screen and (max-width: 950px) {
    section#not-found{
        padding: 167px 0;
    }
}

@media screen and (max-width: 768px) {
    section#not-found{
        padding: 128px 0;
    }
}

@media screen and (max-width: 480px) {
    section#not-found{
        padding: 32px 0;
    }

    section#not-found h3,
    section#not-found p{
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 300px) {
    section#not-found{
        padding: 16px 0;
    }

    section#not-found h3,
    section#not-found p{
        margin: 0;
        padding: 0;
        font-size: 1rem;
    }
}