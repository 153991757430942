#topbar{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    height: var(--topbar-height);
    /* border-bottom: 1px solid var(--secondary); */
}

body.dark #topbar{
    /* border-bottom: 1px solid var(--primary); */
}

#topbar-left{
    color: var(--secondary);
}

body.dark #topbar-left{
    color: var(--primary);
}

#topbar-left h1{
    display: inline;
    font-size: 1rem;
    font-weight: bold;
    animation: winkyText 2s linear infinite;
}

@keyframes winkyText {
    from{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

#topbar-right{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--secondary);
    color: white;
}

body.dark #topbar-right{
    background-color: var(--primary);
}

#topbar-right p a{
    text-decoration: none;
    color: white;
    font-weight: bold;
}

#topbar-right p a:hover{
    text-shadow: 0 0 5px white;
}

#topbar-right::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -40px;
    border-top: 20px solid var(--secondary);
    border-right: 20px solid var(--secondary);
    border-left: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

body.dark #topbar-right::before{
    border-top: 20px solid var(--primary);
    border-right: 20px solid var(--primary);
}

@media screen and (max-width:900px){
    #topbar{
        font-size: 0.9rem;;
    }

    #topbar #topbar-left h1{
        font-size: 0.9rem;
    }

    #topbar-right div.px-4{
        padding-left: 0.5rem !important;
        padding-right: 0.75rem !important;
    }
}

@media screen and (max-width:768px) {
    #topbar{
        flex-direction: column;
        height:max-content;
        gap: 10px;
        padding-left: 0;
    }
    #topbar-right{
        flex-direction: column;
        flex-wrap: wrap;
    }

    #topbar-right::before{
        display: none;
    }

    #topbar-right div:first-child{
        border-right: none !important;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    }
}

@media screen and (max-width: 400px) {
    #topbar{
        padding-left: 0;
    }
}

@media screen and (max-width: 300px) {
    #topbar #topbar-left svg.fa-tooth{
        display: none;
    }
    #topbar #topbar-left h1{
        display: inline-block;
        text-align: center;
        margin: 0;
    }
}
