#privacy-policy{
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: justify;
}

#privacy-policy p.last-update{
    font-weight: bold;
}

#privacy-policy h2,
#privacy-policy h3,
#privacy-policy p{
    padding: 0;
    margin: 0;
}

#privacy-policy p a{
    color: var(--primary);
    font-weight: bold;
}

#privacy-policy p a:hover{
    color: var(--secondary-alt);
}

#privacy-policy h2{
    /* border-bottom: 1px solid var(--primary); */
}

#privacy-policy p {
    padding: 0;
}

#privacy-policy ul{
    list-style-type: none;
    padding: 0;
}

#privacy-policy ul li svg{
    color: var(--primary);
}