#bottombar{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--topbar-height);
}

#bottombar-left{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
}

body.dark #bottombar-left{
    background-color: var(--primary);
}

#bottombar-left h1{
    font-size: 1rem;
    display: inline;
    font-weight: bold;
    color: white;
}

#bottombar-left::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: -40px;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid var(--secondary);
    border-bottom: 20px solid var(--secondary);
}

body.dark #bottombar-left::before{
    border-left: 20px solid var(--primary);
    border-bottom: 20px solid var(--primary);
}

#bottombar-right{
    padding-right: 25px;
    color: var(--secondary);
    font-weight: bold;
}

body.dark #bottombar-right{
    color: var(--primary);
}

#bottombar-right span{
    font-size: 1rem;
}

#bottombar-right span a{
    color: var(--secondary);
    text-decoration: none;
}

#bottombar-right span a:hover{
    color: var(--secondary-alt);
}

body.dark #bottombar-right span a{
    color: var(--primary);
}

body.dark #bottombar-right span a:hover{
    color: var(--secondary-alt);
}

@media screen and (max-width:950px) {
    #bottombar{
        height: 30px;
    }
    #bottombar-left,
    #bottombar-left h1,
    #bottombar-right span{
        font-size: 0.8rem;
    }

    #bottombar-left::before{
        right: -30px;
        border-top: 15px solid transparent;
        border-right: 15px solid transparent;
        border-left: 15px solid var(--secondary);
        border-bottom: 15px solid var(--secondary);
    }
    
    body.dark #bottombar-left::before{
        border-left: 15px solid var(--primary);
        border-bottom: 15px solid var(--primary);
    }
}


@media screen and (max-width:768px) {
    #bottombar{
        flex-direction: column;
        height:max-content;
        gap: 10px;
    }

    #bottombar-left{
        flex-direction: column;
        flex-wrap: wrap;
    }

    #bottombar-left::before{
        display: none;
    }

    #bottombar-left div:first-child{
        border-right: none !important;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
        text-align: center;
    }

    #bottombar-right{
        padding-right: 0;
    }
}

@media screen and (max-width:300px){
    #bottombar-right{
        text-align: center;
    }
}