nav{
    border-top: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
}

nav.bg-body-tertiary{
    background-color: #21201d !important;
}

a.navbar-brand{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

a.navbar-brand h1{
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    color: var(--secondary);
}

body.dark a.navbar-brand h1{
    color: var(--primary);
}

a.navbar-brand:hover{
    filter: drop-shadow(0px 0px 5px var(--primary));
}

.nav-link{
    color: var(--secondary) !important;
    font-weight: bold !important;
}

body.dark .nav-link{
    color: var(--primary) !important;
}

.nav-link:hover{
    filter: drop-shadow(0px 0px 10px var(--primary));
}

.dark .nav-link:hover{
    filter: drop-shadow(0px 0px 5px var(--primary));
}

.navbar-nav{
    gap: 20px;
}

.navbar-nav svg.fa-sun,
.navbar-nav svg.fa-moon{
    width: 16px;
    height: 16px;
}

.navbar-toggler{
    border-width: 2px !important;
    border-color: var(--primary) !important;
}

.navbar-toggler span{
    background-image: url("../../assets/icons/collapseButton.svg");
}

@media screen and (max-width: 991px){
    nav{
        height: unset;
    }
    .navbar-nav{
        gap: unset;
    }
}

@media screen and (max-width: 450px){
    a.navbar-brand h1{
        font-size: 0.75rem;
    }
    a.navbar-brand img.logo{
        width: 36px;
        height: 36px;
    }
    nav.navbar button.navbar-toggler{
        padding: 0.25rem;
        font-size: 0.75rem;
    }
    nav.navbar div.navbar-collapse div.navbar-nav{
        font-size: 0.75rem;
    }
    .navbar-nav svg.fa-sun{
        width: 12px;
        height: 12px;
    }
}