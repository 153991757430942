#aboutContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

#about{
    max-width: 1280px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    --about-height: 500px;
}

.aboutLeft,
.aboutRight{
    height: var(--about-height);
}

.aboutRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.aboutLeft img{
    height: 100%;
}

.aboutRight h4{
    position: relative;
    padding-left: 85px;
    color: var(--secondary);
    width: fit-content;
}

body.dark .aboutRight h4{
    color: var(--primary);
}

.aboutRight h4::before{
    content: "";
    position: absolute;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--secondary);
    top: 13px;
    left: 10px;
}

.aboutRight h4::after{
    content: "";
    position: absolute;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--secondary);
    top: 13px;
    right: -75px;
}

body.dark .aboutRight h4::before,
body.dark .aboutRight h4::after{
    background-color: var(--primary);
}

body.dark .aboutRight h4::before,
body.dark .aboutRight h4::after{
    background-color: var(--primary);
}

.aboutRight h1, p{
    padding-left: 10px;
}

.aboutRight p{
    text-align: justify;
}

.badges{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.badgesLeft,
.badgesRight{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}

.badgesLeft p,
.badgesRight p{
    display: flex;
    gap: 20px;
    text-align: left;
}


@media screen and (max-width: 1200px) {
    #about{
        flex-direction: column;
    }
    .aboutRight{
        align-items: center;
    }
    .badges{
        width: 75%;
    }
    .badgesLeft, .badgesRight{
        align-items: center;
    }
    .aboutRight h1{
        text-align: center;
    }
    .aboutRight h4{
        padding-left: unset;
    }
    .aboutRight h4::before{
        left: -65px;
    }
    .aboutRight h4::after{
        right: -65px;
    }
}

@media screen and (max-width:768px) {
    #aboutContainer{
        padding-bottom: 0;
    }
    #about{
        --about-height: 350px;
    }
    
    .aboutRight h4{
        font-size: calc(1rem + .6vw);
    }

    .aboutRight h4::before,
    .aboutRight h4::after{
        height: 4px;
        top: 11px;
    }

    .aboutRight h4::after{
        right: -65px;
    }

    #about .aboutRight h1{
        font-size: 1.5rem;
    }

    #about .aboutRight p{
        font-size: 0.8rem;
    }

    #about .aboutRight .badges .badgesLeft p{
        width: 100%;
    }
}

@media screen and (max-width: 640px) {

    .badges{
        width: 100%;
    }

    .aboutLeft img{
        width: 350px;
        height: 350px;
    }
    
}

@media screen and (max-width: 400px) {
    .aboutLeft{
        height: 240px;
    }
    .aboutLeft img{
        width: 240px;
        height: 240px;
    }
    .aboutRight{
        height: unset;
    }

    .aboutRight p:nth-child(3){
        padding: 10px;
    }
    #about .aboutRight .badges p {
        font-size: 0.55rem;
        gap: 5px;
    }

    #about .aboutRight .badges svg{
        width: 16px;
        height: 16px;
    }
}