.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1f1f1;
    padding: 15px;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(33, 32, 29, 0.1);
    z-index: 1000;
    border-top: 1px solid var(--primary);
}

body.dark .cookie-banner{
    background: #494949;
}

.cookie-banner p {
    margin: 0 0 10px;
    font-size: 14px;
    /* color: #333; */
}

.cookie-banner a {
    font-weight: bold;
    color: var(--primary);
    text-decoration: underline;
}

.cookie-banner a:hover{
    color: var(--secondary-alt);
}

.cookie-banner button {
    background: var(--primary);
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
}

.cookie-banner button:hover {
    background: var(--secondary-alt);
}