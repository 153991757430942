@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-VariableFont.ttf');
}

:root {
    --alternative: hsl(28, 50%, 70%);
    --alternative2: hsl(28, 70%, 70%);

    /* --secondary: hsl(28, 70%, 80%); */
    
    --primary: #D19A35;
    --secondary: #D19A35;
    --secondary-alt: #F5C865;


    --gold: linear-gradient(to right, #A36A28, #D19A35, #F5C865, #A36B28, #FBD572);
    --gray: linear-gradient(to right, #787776, #757575, #969697, #6F7070, #666666);


    --topbar-height: 40px;
    --navbar-height: 84px;

    --bg-dark: #21201d;
}

*, body{
    font-family: "Roboto";
}

::selection{
    /* TODO */
    background-color: var(--primary);
    color: white;
}

.icons{
    color: var(--secondary);
}

body.dark .icons{
    color: var(--primary);
}

::-webkit-scrollbar{
    display: none;
}

body.dark{
    background-color: var(--bg-dark);
    color: white;
}

.svgIcon{
    width: 24px;
    height: 24px;
    fill: var(--secondary);
}

body.dark .svgIcon{
    fill: var(--primary);
}

h1, h2, h3, h4, h5, h6 {
    color: var(--primary);
}