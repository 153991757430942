section#dentistCardsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    gap: 50px;
    --image-height : 300px;
    --image-width : 320px;
    --h3-height : 44px;
    --h2-height : 40px;
    --social-height : 40px;
    --hr-height : 1px;
}

section#dentistCardsContainer .dentistCard{
    position: relative;
    width: var(--image-width);
    height: calc(var(--image-height) + var(--h3-height) + var(--h2-height) + var(--hr-height));
    transform-style: preserve-3d;
    perspective: 1000px;
}

section#dentistCardsContainer .dentistCard .box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: 1s ease;
}

section#dentistCardsContainer .dentistCard:hover .box{
    transform: rotateY(180deg);
}

section#dentistCardsContainer .dentistCard .box .imgBx{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

section#dentistCardsContainer .dentistCard .box .imgBx img{
    width: 100%;
    height: var(--image-height);
    object-fit: fill;
    border: 2px solid var(--secondary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

body.dark section#dentistCardsContainer .dentistCard .box .imgBx img{
    border: 2px solid var(--primary);
}

section#dentistCardsContainer .dentistCard .box .imgBx h3{
    text-align: center;
    width: 100%;
    height: var(--h3-height);
    background-color: var(--secondary);
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 10px 0;
}

body.dark section#dentistCardsContainer .dentistCard .box .imgBx h3{
    background-color: var(--primary);
}

section#dentistCardsContainer .dentistCard .box .imgBx hr{
    margin: 0;
    opacity: 1;
    border-color: white;
}

section#dentistCardsContainer .dentistCard .box .imgBx h2{
    text-align: center;
    width: 100%;
    height: var(--h2-height);
    background-color: var(--secondary);
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 0;
    font-size: 1rem;
    padding: 10px 0;
}

body.dark section#dentistCardsContainer .dentistCard .box .imgBx h2{
    background-color: var(--primary);
}

section#dentistCardsContainer .dentistCard .box .imgBx p{
    background-color: var(--secondary);
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

body.dark section#dentistCardsContainer .dentistCard .box .imgBx p{
    background-color: var(--primary);
}

section#dentistCardsContainer .dentistCard .box .contentBx{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
    border: 2px solid var(--primary);
    border-radius: 10px;
    background-color: var(--primary);
}

section#dentistCardsContainer .dentistCard .box .contentBx div{
    transform-style: preserve-3d;
    padding: 20px;
    background-color: white;
    transform: translateZ(100px);
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
}

body.dark section#dentistCardsContainer .dentistCard .box .contentBx div{
    background-color: var(--bg-dark);
}

section#dentistCardsContainer .dentistCard .box .contentBx div h3{
    color: var(--primary);
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 8px;
}

section#dentistCardsContainer .dentistCard .box .contentBx div p{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 24px;
    padding: 0;
    color: var(--bg-dark);
}

section#dentistCardsContainer .dentistCard .box .contentBx div p:last-child{
    margin-bottom: 0px;
}

body.dark section#dentistCardsContainer .dentistCard .box .contentBx div p{
    color: white;
}

@media screen and (max-width:800px){
    section#dentistCardsContainer{
        gap: 50px;
        --image-height : 225px;
        --image-width : 240px;
        --h3-height : 40px;
        --h2-height : 36px;
        --hr-height : 1px;
    }

    section#dentistCardsContainer .dentistCard .box .imgBx h3{
        font-size: 1rem;
    }
    section#dentistCardsContainer .dentistCard .box .imgBx h2{
        font-size: 0.75rem;
    }
    section#dentistCardsContainer .dentistCard .box .contentBx div h3{
        font-size: 0.75rem;
    }
    section#dentistCardsContainer .dentistCard .box .contentBx div p{
        font-size: 0.75rem;
    }
}