section#googleMap{
    position: relative;
    height: 480px;

}

/* section#googleMap h1{
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    color: #ab0000;
    animation: mapTextAnim linear infinite 2s;
}

@keyframes mapTextAnim {
    from{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
} */

section#googleMap iframe{
    width: 100%;
    border: 0px;
    height: 100%;
}